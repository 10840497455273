import React, { Component } from 'react';

import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Blog from './pages/Blog';
import Post from './pages/Post';
import Track from './pages/Track';
import Order from './pages/Order';
import Culture from './pages/Culture';


import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';

class App extends Component {
  render() {

    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/blog/science&engineering" component={Blog} />
          <Route exact path="/blog/art&culture" component={Culture} />
          <Route exact path="/post/:slug" component={Post} />
          <Route exact path="/track" component={Track} />
          <Route exact path="/track/:slug" component={Order} />
          <Route exact path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
      </Router>
    );
  }
}

export default App;
