import styles from './Product.module.css';
import { MdCheckCircle } from "react-icons/md";

function BlogCard(props) {
  return (
    <div className={styles.full}>
      <div className={styles.order}>
        <h3>product {props.product.serial.current.replace(/(?=(?:.{3})*$)/g, '-').slice(1).slice(0, -1)}</h3>
      </div>
      <div className={styles.card}>
        <h1>{props.product.name}</h1>
        <h2>{props.product?.tags[0]?.name}</h2>
        <p>{props.product.description}</p>
        {!props.product.closed ? <h3>Last Update: <a>{props.product?.dateStart}</a></h3> : <></>}
        {props.product.closed ? <h3>Completed: <a style={{ color: '#538452' }}>{props.product?.dateFinish} <MdCheckCircle /></a></h3> : <></>}
      </div>
    </div>

  );
}
export default BlogCard;