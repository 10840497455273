/* eslint-disable jsx-a11y/anchor-has-content */
import styles from './Blog.module.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { urlFor, client } from "../../client";
import { MdOutlineSouth, MdFace } from "react-icons/md";
import BlogCard from "../../components/BlogCard"
import Loader from "../../components/Loader"
import Helmet from "react-helmet"


function Blog() {

  const [posts, setPosts] = useState([]);
  const [color, setColor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [post, setPost] = useState({});


  useEffect(() => {
    execute();
  }, []);

  const execute = async () => {

    const query = `*[_type == "blog"]{highlight2->{title, slug, isBlogPost, summary, date, banner, description, repository, logo, status->{name, color}, tags[]->{name}}}
      `;
    await client.fetch(query).then((data) => { setPost(data[0]); setIsLoading(false) });

    const query2 = `*[_type == "project" && isCultural == true && isPrivate == false]{title, slug, isBlogPost, isCultural, summary, date, banner, description, repository, logo, status->{name, color}, tags[]->{name}}
      `;
    client.fetch(query2).then((data) => { setPosts(data); setIsLoading2(false) });

  }


  if (isLoading || isLoading2) {
    return <Loader></Loader>
  } else {
    return (

      <div className={styles.todo}>
          <Helmet>
        <title>Art & Culture</title>
        <meta property="og:title" content="Art & Culture" />
        <meta property="og:description"
          content="Based in Lisbon, Portugal. Jorge is a Software Engineer currently working with Front-end Engineering: Building powerful, secure, fast and modern PWAs solutions." />
        <meta name="description"
          content="Based in Lisbon, Portugal. Jorge is a Software Engineer currently working with Front-end Engineering: Building powerful, secure, fast and modern PWAs solutions."></meta>
      </Helmet>
        <Link to="/" aria-label="Go Back">
          <div className={styles.redirect}>
            <p><MdFace /></p>
          </div>
        </Link>
        <div className={styles.wrapper} id="top">

          <div className={styles.header}>
            <h1>Art & Culture</h1>
          </div>
          <Link to={"/post/" + post?.highlight2?.slug?.current}>
          <div key={post?.highlight2?.slug?.current} className={post?.highlight2?.isBlogPost ? styles.bannerB : styles.banner}>
            <img src={post?.highlight2?.banner ? urlFor(post?.highlight2?.banner).url() : "n"} alt="Post highlight Banner" />
            <div className={styles.info}>
              <div className={styles.h3}>{post?.highlight2?.tags[0]?.name}</div>
              <h1>{post?.highlight2?.title}</h1>
              <p>{post?.highlight2?.summary}</p>
            </div>
          </div>
          </Link>

          <div className={styles.posts}>
            {
              
              posts?.map((post2, index) => (

                post?.highlight2?.slug?.current  !== post2.slug?.current? <BlogCard key={post2.slug?.current} id={post2.slug?.current + "Child"} link={post2.slug} isBlogPost={index % 2 === 0? false : true} color={color} setColor={setColor} isCultural={true} tag={post2.tags[0]?.name} title={post2.title} summary={post2.summary} /> : console.log()

              ))
            }

          </div>


          <div className={styles.load}>
          </div>

          <div className={styles.footer}>
            <a href="#top">load more<br></br>< MdOutlineSouth /></a>
          </div>
        </div>
      </div>

    );
  }

}

export default Blog;

