/* eslint-disable jsx-a11y/anchor-has-content */
import styles from './Track.module.css';
import React, { useState, useEffect } from 'react';
import { client } from "../../client";
import { useParams, Link } from 'react-router-dom';
import { MdLocalOffer } from "react-icons/md";
import Status from '../../components/Status';
import Product from '../../components/Product';
import Loader from "../../components/Loader"
import Helmet from "react-helmet"


function Post() {
  const { slug } = useParams();

  const serial2 = slug.replaceAll('-', '');

  const [order, setOrder] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    execute();
  }, [slug]);


  
  const execute = async () => {
    const query = `*[serial.current == "${serial2}"]{serial, express, status, dateStart, deadline, finished, dateFinish, products[]->{name, serial, description, tags[]->{name}, closed, dateStart, dateFinish}}`;

    await client.fetch(query).then((data) => {
      setOrder(data[0])
      setIsLoading(false)
    });
  }

  const name = "Order " + order?.serial?.current

  if (isLoading) {
    return <Loader></Loader>
  } else {
    if (!order?.serial?.current) {
      return (
        <div className={styles.todo}>

<Helmet>
        <title>Track Order</title>
        <meta property="og:title" content="Track Order" />
        <meta property="og:description"
          content="Stay up to date about your service progress" />
        <meta name="description"
          content="Stay up to date about your service progress"></meta>
      </Helmet>

          <Link to="/track" aria-label="Go Back">
            <div className={styles.redirect}>
              <p><MdLocalOffer /></p>
            </div>
          </Link>
          <div className={styles.wrapper} id="top">
            <div className={styles.header}>
              <h1>Oops!</h1>
              <h2>Seems your tracking code is invalid. <br /> Track codes have nine digits in the following pattern: (xxx-xxx-xxx)<br /><br />Try again typing or pasting the code you recieved in your email account
              </h2>

              <Link to="/track" aria-label="Go Back">
                <div className={styles.search}><p>try again</p></div>
              </Link>

            </div>


          </div>
        </div>
      );
    }
    else {
      return (
        <div className={styles.todo}>

<Helmet>
        <title>{name}</title>
        <meta property="og:title" content={name} />
        <meta property="og:description"
          content="Stay up to date about your service progress" />
        <meta name="description"
          content="Stay up to date about your service progress"></meta>
      </Helmet>

          <Link to="/track" aria-label="Go Back">
            <div className={styles.redirect}>
              <p><MdLocalOffer /></p>
            </div>
          </Link>
          <div className={styles.wrapper} id="top">
            <div className={styles.header}>
              <h1>Track Order</h1>
            </div>
            <div className={styles.status}>
              <Status order={order} />
            </div>

            <div className={styles.products}>
              {order?.products?.map((product, index) => (
                <Product key={index + product?.serial?.current} product={product} order={order} />
              ))}
            </div>



          </div>
        </div>
      )
    }
  }



}

export default Post;

