import styles from './Title.module.css';

function Title(props) {
 
  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <h1>{props.title}</h1>
      </div>
      <div className={styles.underlineHeading}></div>
    </div>

  );
}

export default Title;