/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import styles from './Work.module.css';
import { ButtonG, ProjectCard, Title } from '../../../../components/components'
import { urlFor, client } from '../../../../client';
import AppImage from "../../../../assets/images/app.svg"
import {Link } from 'react-router-dom';

function Work() {

  const [work, setWork] = useState([]);

  const github = "https://www.github.com/" + work.githubUsername;

  useEffect(() => {
    execute();
  }, []);

  const execute = async () => {
    const query = `*[_type == "work"]{
      description, githubUsername,
      projects[]->{title, slug, description, repository, logo, status->{name, color}, tags[]->{name}}
    }`;

    await client.fetch(query).then((data) => { setWork(data[0]);});
  }


  return (

    <div className={styles.todo}>
      <div className={styles.wrapper}>

        <div className={styles.left}>

          <div className={styles.cards}>
            {work.projects?.map((project, index) => (
              <ProjectCard key={project?.title + index} link={"/post/"+project?.slug?.current} title={project?.title} tag={project?.tags.map((tag, num) => (

              num === project?.tags?.length -1? tag.name : tag.name + ", "

              ))} color={project.status.color} status={project.status.name} logo={project.logo ? urlFor(project.logo).url() : "n"}></ProjectCard>

            ))}
          </div>

          <div className={styles.link}><Link to="/blog/science&engineering">see all projects »</Link></div>
        </div>

        <div className={styles.right}>
          <div className={styles.target}></div>
          <img src={AppImage} className={styles.app} alt="Two hands using a mobile phone." />
          <div className={styles.content}>
            <Title title={"Projects"}></Title>
            <p>{work.description}</p>
          </div>

          <div className={styles.but}>
            <ButtonG link={github} name={"github"} icon={"FaGithub"}></ButtonG>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Work;

