/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import styles from './Contact.module.css';
import ContactImage from '../../../../assets/images/noConnection.svg';
import { ButtonB, Title } from '../../../../components/components'
import { client } from '../../../../client';
import { FaFacebookF, FaInstagram, FaTwitter, FaGithubAlt, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from "react-icons/fa";

/* FALTA REORGANIZAR NAS QUERIES O TOP E BOTTOM QUE SAO NOVOS, ALEM DE AJUSTAR OS ELEMENTOS QUE COMPOE BOTTOM */

function Contact() {

  const [contact, setContact] = useState([]);

  useEffect(() => {
    execute();
  }, []);

  const execute = async () => {
    const query = '*[_type == "contact"]';
    await client.fetch(query).then((data) => { setContact(data[0]);});
  }

  return (
    <div className={styles.todo}>
      <div className={styles.wrapper}>


        <div className={styles.leftContact}>
          <Title title={"Get in Touch"}></Title>
          <p>Don't be shy, say hi and feel free to make any question</p>

          <div className={styles.info}>
            <div className={styles.infop}>< FaMapMarkerAlt />{contact.adress}</div>
            <div className={styles.infop}>< FaPhoneAlt /><a href={"tel:" + (contact.phone + "").split(' ').join('')} target="_blank" rel='noreferrer'> {contact.phone}</a></div>
            <div className={styles.infop}>< FaEnvelope /><a href={"mailto:" + contact.mail} target="_blank" rel='noreferrer'> {contact.mail}</a></div>
          </div>
        </div>

        <div className={styles.rightContact}>
          <div className={styles.telephone}>
            <img src={ContactImage} className={styles.telephone2} alt="Two people on telephone." />
          </div>
        </div>





        <div className={styles.leftOne}>
          <div className={styles.cardConnection}>
            <div className={styles.connectionLeft}>
              <h2>connect</h2>
              <div className={styles.space}></div>
              <h2>with me</h2>
            </div>

            <div className={styles.connectionRight}>
              <div className={styles.icons}>

                <div onClick={() => { window.open("https://www.facebook.com/" + contact.username, "_blank")} }><div className={styles.circle}>< FaFacebookF /></div></div>
                <div  onClick={() => { window.open("https://www.instagram.com/" + contact.username, "_blank")}  }> <div className={styles.circle}>< FaInstagram /></div></div>
                <div onClick={() => { window.open("https://www.twitter.com/" + contact.username, "_blank")}  }> <div className={styles.circle}>< FaTwitter /></div></div>
                <div  onClick={() => { window.open("https://www.github.com/" + contact.username, "_blank")}  }> <div className={styles.circle}>< FaGithubAlt /></div></div>
                <div  onClick={() => { window.open("https://www.linkedin.com/in/" + contact.username, "_blank")}  }> <div className={styles.circle}>< FaLinkedinIn /></div></div>


              </div>
              <div className={styles.tag}>
                <p>@{contact.username}</p>
              </div>
            </div>

          </div>
        </div>
        <div className={styles.rightOne}>
          <div className={styles.cardTalk}>
            <div className={styles.talkTop}>

              <ButtonB link={"https://api.whatsapp.com/send/?phone=" + contact.phone + "&text=Hey,+Jorge!"} name={"whatsapp"} icon={"FaWhatsapp"}></ButtonB>
              <div className={styles.space2}></div>
              <ButtonB link={"https://t.me/" + contact.username} name={"telegram"} icon={"FaTelegramPlane"}></ButtonB>

            </div>
            <div className={styles.talkBottom}>
              <p>designed and developed by <span className={styles.jorgeSiqueira}>Jorge Siqueira</span></p>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}

export default Contact;

