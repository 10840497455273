import styles from './Button.module.css';
import { FaLinkedin, FaGithub, FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';

function ButtonM (props) {
  let icon = undefined;
  switch (props.icon) {
    case "FaLinkedin":
      icon = <FaLinkedin></FaLinkedin>;
      break;
    case "FaGithub":
      icon = <FaGithub></FaGithub>;
      break;
    case "FaWhatsapp":
      icon = <FaWhatsapp></FaWhatsapp>;
      break;
    case "FaTelegramPlane":
      icon = <FaTelegramPlane></FaTelegramPlane>;
      break;
    default:
      break;
  }

  return (
    <a href={props.link} target="_blank" rel='noreferrer'><div className={styles.button}> {icon} {props.name}</div></a>
  );
}

export default ButtonM;