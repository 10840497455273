/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import Hero from './Sections/Hero';
import About from './Sections/About';
import Contact from './Sections/Contact';
import Work from './Sections/Work';
import Education from './Sections/Education';
import Helmet from "react-helmet"


function Home() {
  console.log("You are a curious person, aren't you?\nWelcome to my personal website. This is a SPA using React and Sanity.\nFeel free to check this app full code at my Github: @jorgsiq")


  return (
    <div>
      <Helmet>
        <title>Jorge Siqueira - Software Engineer</title>
        <meta property="og:title" content="Jorge Siqueira - Software Engineer" />
        <meta property="og:description"
          content="Based in Lisbon, Portugal. Jorge is a Software Engineer currently working with Front-end Engineering: Building powerful, secure, fast and modern PWAs solutions." />
        <meta name="description"
          content="Based in Lisbon, Portugal. Jorge is a Software Engineer currently working with Front-end Engineering: Building powerful, secure, fast and modern PWAs solutions."></meta>
      </Helmet>

      <div id="hero">
        <Hero />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="education">
        <Education />
      </div>

      <div id="work">
        <Work />
      </div>

      <div id="contact">
        <Contact />
      </div>

    </div>
  );
}



export default Home;

