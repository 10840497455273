import styles from './BlogCard.module.css';
import {Link} from "react-router-dom"
function BlogCard(props) {

  return (
    <Link to = {"/post/" + props.link?.current}><div className={styles.target}>
      <div key = {props.id} className={(props?.isBlogPost? styles.cardB : styles.card)}>
        <h1>{props.title}</h1>
        <h2>{props.summary}</h2>
        {props.isCultural? 
         <h3>{props.tag}</h3>
        :
        <h3>{props.isBlogPost? "Blog Post" : "Project"}</h3>
      }
       
      </div>
    </div>
    </Link>
  );
}
export default BlogCard;