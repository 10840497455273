/* eslint-disable jsx-a11y/anchor-has-content */
import styles from './Track.module.css';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MdFace, MdEast } from "react-icons/md";
import { Helmet } from "react-helmet";


function Post() {


  const handleKeyDown = (event) => {
    if (event.key === 'Backspace') {
      if (serial.slice(-1) === "-") {
        setSerial(serial.slice(0, -1))
        setDeleted(true);
      }

    }
    else {
      if (deleted === true) {
        setDeleted(false);
        if (event.target.value.length === 4 || event.target.value.length === 8) {
          if (event.target.value.slice(-1) !== "-") {
            let i = event.target.value.slice(-1);
            setSerial(serial.slice(0, -1) + "-" + i)
          }
        }
      }

    }
  };


  const serialHandle = (serial) => {

    if ((serial.length === 3 || serial.length === 7) && !deleted) {
      setSerial(serial + "-");
    }
    else {
      setSerial(serial);
    }
  }

  const [deleted, setDeleted] = useState(false);
  const [serial, setSerial] = useState("xxx-xxx-xxx");

  const [checked, setChecked] = useState(false);
  const [error, setError] = useState(false);


  useEffect(() => {
    if (serial.replaceAll('-', '').length === 9 && serial.replaceAll('-', '') !== "xxxxxxxxx") {
      setError(false);
      setChecked(true);
    }
    else {
      setChecked(false);
    }
  }, [serial]);

  return (


    <div className={styles.todo}>

      <Helmet>
        <title>Track Order</title>
        <meta property="og:title" content="Track Order" />
        <meta property="og:description"
          content="Stay up to date about your service progress" />
        <meta name="description"
          content="Stay up to date about your service progress"></meta>
      </Helmet>

      <Link to="/" aria-label="Go Back">
        <div className={styles.redirect}>
          <p><MdFace /></p>
        </div>
      </Link>
      <div className={styles.wrapper} id="top">
        <div className={styles.header}>
          <h1>Track Order</h1>
          <h2>use the code in your payment confirmation email to stay up to date about your service progress</h2>
        </div>
        <div className={styles.sub}>

          <div className={styles.bar}><input type="text" pattern="[a-zA-Z0-9]*" name="serial" maxLength="11" value={serial} onKeyDown={handleKeyDown} onChange={(e) => { serialHandle(e.target.value) }} /></div>

          {checked ?
            <Link to={"/track/" + serial} aria-label="Track"><div className={styles.search}><p><MdEast /></p></div></Link>
            :
            <div className={styles.searchOff} onClick={() => { setError(true) }}><p><MdEast /></p></div>
          }

        </div>
        {
          error ? <p className={styles.error}>first you must type or paste a valid code<br /></p> : <p></p>
        }



      </div>
    </div>
  );




}

export default Post;

