/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styles from './NotFound.module.css';
import ErrorImage from '../../assets/images/taxiProgramming.svg';
import { Link } from "react-router-dom";
import Helmet from "react-helmet";

function NotFound() {
  return (
    <div className={styles.todo}>
      <Helmet>
        <title>Not Found</title>
        <meta property="og:title" content="Not Found" />
        <meta property="og:description"
          content="Page not found." />
        <meta name="description"
          content="Page not found."></meta>
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.notFound}>
          <img src={ErrorImage} alt="404 Not Found Illustration" />
        </div>
        <div className={styles.textContent}>
          <h3>Page not Found!</h3>
        </div>
        <Link to="/" aria-label="Go Back">
          <div className={styles.redirect}>
            <p>back home</p>
          </div>
        </Link>


      </div>
    </div>
  );
}

export default NotFound;

