import styles from './Button.module.css';
import { FaLinkedin, FaGithub, FaFigma, FaWhatsapp, FaTelegramPlane, FaSpotify, FaYoutube, FaTwitch, FaInstagram, FaTwitter, FaGlobeAmericas } from 'react-icons/fa';

function ButtonG(props) {
  let icon = undefined;
  let name = undefined;
  switch (props.icon) {
    case "FaLinkedin":
      icon = <FaLinkedin></FaLinkedin>;
      name = "LinkedIn";
      break;
    case "FaSpotify":
      icon = <FaSpotify></FaSpotify>;
      name = "Spotify";
      break;
    case "FaYoutube":
      icon = <FaYoutube></FaYoutube>;
      name = "YouTube";
      break;
    case "FaTwitch":
      icon = <FaTwitch></FaTwitch>;
      name = "Twitch";
      break;
    case "FaInstagram":
      icon = <FaInstagram></FaInstagram>;
      name = "Instagram";
      break;
      case "FaFigma":
        icon = <FaFigma></FaFigma>;
        name = "Figma";
        break;
    case "FaTwitter":
      icon = <FaTwitter></FaTwitter>;
      name = "Twitter";
      break;
    case "FaGlobeAmericas":
      icon = <FaGlobeAmericas></FaGlobeAmericas>;
      name = "Preview";
      break;
    case "FaGithub":
      icon = <FaGithub></FaGithub>;
      name = "GitHub";
      break;
    case "FaWhatsapp":
      icon = <FaWhatsapp></FaWhatsapp>;
      name = "Whatsapp";
      break;
    case "FaTelegramPlane":
      icon = <FaTelegramPlane></FaTelegramPlane>;
      name = "Telegram";
      break;
    default:
      break;
  }

  return (
    icon === undefined? <></> : <a href={props.link} target="_blank" rel='noreferrer'><div className={styles.button}> {icon} {name}</div></a>
  );
}

export default ButtonG;