/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import styles from './Hero.module.css';
import WelcomeImage from '../../../../assets/images/taxiWelcome.svg';
import { Title } from '../../../../components/components'
import { Link } from "react-router-dom";
import { MdEast, MdClear, MdDehaze, MdKeyboardArrowDown } from "react-icons/md";

function Hero() {

  const [on, setOn] = useState(false);

  const [isMobile, setIsMobile] = useState(false)

  //choose the screen size 
  const handleResize = () => {
    if (window.innerWidth < 700) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  // create an event listener
  useEffect(() => {

    window.addEventListener("resize", handleResize)
  })

  useEffect(() => {
    window.innerWidth < 700 ? setIsMobile(true) : setIsMobile(false)
  }, [])

  return (



    <div className={styles.todo}>

      <div onClick={() => { setOn(!on) }} className={on? styles.redirectClosed : styles.redirect}>
        <p>{on ? <MdClear /> : <MdDehaze />}</p>
      </div>

      <div className={styles.wrapper}>


        <div className={styles.topHome}>

          <div className={styles.mobileOnly}>
            <Title title={"Hey, Nice to See You!"}></Title>
          </div>

          <div className={styles.leftHome}>
            <div className={styles.fitElements}>
              <Title title={"Hey, nice to see you!"}></Title>
              <div>
                <h2>I'm Jorge Siqueira</h2>
                <p>I help people to bring <br /> good ideas into reality</p>
              </div>

              <div className={styles.menu}>
                <nav>
                  <ol>
                    <li><a href="#about">About</a></li>
                    <li><a href="#education">Education</a></li>
                    <li><a href="#work">Work</a></li>
                    <li><a href="#contact">Contact</a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

        </div>

        <div className={styles.rightHome}>
          <img src={WelcomeImage} alt="Cosmonaut Illustration" />
        </div>

        <div className={styles.mobileOnly}>
          <div className={styles.description}>
            <h2>I'm Jorge Siqueira</h2>
            <p>I help people to bring <br /> good ideas into reality</p>
          </div>
        </div>


        <div className={styles.sideHome}>
          <a href="#about"><div className={styles.sideButton}>scroll down <MdEast /> </div></a>
        </div>
        {!isMobile ?
          <div className={styles.bottomHome}>
            <nav>
              <ul>
                <li><Link to="/blog/art&culture">Art & Culture</Link></li>
                <li><div className={styles.dotSpace}></div></li>
                <li><Link to="/blog/science&engineering">Science & Engineering</Link></li>
                <li><div className={styles.dotSpace}></div></li>
                <li><Link to="/track">Track Order</Link></li>
              </ul>
            </nav>
          </div>

          :

          <div className={!on ? styles.bottomHomeHide : styles.bottomHome}>
            <nav>
              <ul  style={!on ? { display: "none" } : {}}>
                <li><Link className={styles.li} to="/blog/art&culture">Art & Culture</Link></li>
                <li><div className={styles.dotSpace}></div></li>
                <li><Link className={styles.li} to="/blog/science&engineering">Engineering</Link></li>
                <li><div className={styles.dotSpace}></div></li>
                <li><Link className={styles.li} to="/track">Track Order</Link></li>
              </ul>
            </nav>
          </div>


        }

        <div className={styles.mobileOnly}>
        <a href="#about"><div className={styles.circleNav}>< MdKeyboardArrowDown /></div></a>
        </div>

      </div>
    </div>
  );
}

export default Hero;

