import styles from './Status.module.css';
import { MdCheckCircle} from "react-icons/md";


function Status(props) {
  const stage = parseInt(props.order.status);

  return (

    <div className={styles.full}>
      <div className={styles.order}>
        <h3>order {props.order.serial.current.replace(/(?=(?:.{3})*$)/g, '-').slice(1).slice(0, -1)}</h3>
      </div>

      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.left}>
            <h1>Order Status</h1>
            <h2>{props.order?.express? "Express Service" : "Standard Service"}</h2>
          </div>
          <div className={styles.right}>
            {stage !== 6 ? <h2>Deadline: <a>{props.order.deadline}</a></h2> : <></>}
            {stage !== 6 ? <h2>Last Update: <a>{props.order.dateStart}</a></h2> : <></>}
            {stage === 6 ? <h2>Completed: <a style={{color: '#538452'}}>{props.order?.dateFinish} <MdCheckCircle/></a></h2> : <></>}
          </div>
        </div>
        <div className={styles.down}>


          <div className={styles.square}>
            <div className={styles.toptop}>
              <div style={stage > 0 ? {background: '#FDE07F'} : {}} className={styles.leftleft}>
                <div style={stage > 0 ? {background: '#FDE07F'} : {}} className={styles.ball}></div>

              </div>
              <div style={stage > 1 ? {background: '#FDE07F'} : {}} className={styles.rightright}></div>
            </div>
            <div className={styles.downdown}>
              <h1 style={stage > 0 ? {color: '#FDE07F'} : {}}>Payment</h1>
              <h2 className={styles.mobileOnly} style={stage === 1 ? {color: '#FDE07F'} : {display: "none"}}>Payment</h2>
            </div>
          </div>

          <div className={styles.square}>
            <div className={styles.toptop}>
              <div style={stage > 1 ? {background: '#FDE07F'} : {}} className={styles.leftleft}>
                <div style={stage > 1 ? {background: '#FDE07F'} : {}} className={styles.ball}></div>
              </div>
              <div style={stage > 2 ? {background: '#FDE07F'} : {}} className={styles.rightright}></div>
            </div>
            <div className={styles.downdown}>
              <h1 style={stage > 1 ? {color: '#FDE07F'} : {}}>Design</h1>
              <h2 className={styles.mobileOnly} style={stage === 2 ? {color: '#FDE07F'} : {display: "none"}}>Design</h2>
            </div>
          </div>

          <div className={styles.square}>
            <div className={styles.toptop}>
              <div style={stage > 2 ? {background: '#FDE07F'} : {}} className={styles.leftleft}>
                <div style={stage > 2 ? {background: '#FDE07F'} : {}} className={styles.ball}></div>
              </div>
              <div style={stage > 3 ? {background: '#FDE07F'} : {}} className={styles.rightright}></div>
            </div>
            <div className={styles.downdown}>
              <h1 style={stage > 2 ? {color: '#FDE07F'} : {}}>Prototype</h1>
              <h2 className={styles.mobileOnly} style={stage === 3 ? {color: '#FDE07F'} : {display: "none"}}>Prototype</h2>
            </div>
          </div>

          <div className={styles.square}>
            <div className={styles.toptop}>
              <div style={stage > 3 ? {background: '#FDE07F'} : {}} className={styles.leftleft}>
                <div style={stage > 3 ? {background: '#FDE07F'} : {}} className={styles.ball}></div>
              </div>
              <div style={stage > 4 ? {background: '#FDE07F'} : {}} className={styles.rightright}></div>
            </div>
            <div className={styles.downdown}>
              <h1 style={stage > 3 ? {color: '#FDE07F'} : {}}>Production</h1>
              <h2 className={styles.mobileOnly} style={stage === 4 ? {color: '#FDE07F'} : {display: "none"}}>Production</h2>
            </div>
          </div>

          <div className={styles.square}>
            <div className={styles.toptop}>
              <div style={stage > 4 ? {background: '#FDE07F'} : {}} className={styles.leftleft}>
                <div style={stage > 4 ? {background: '#FDE07F'} : {}} className={styles.ball}></div>
              </div>
              <div style={stage > 5 ? {background: '#FDE07F'} : {}} className={styles.rightright}>
                <div style={stage > 5 ? {background: '#FDE07F'} : {}} className={styles.ballB}></div>
             
              </div>
            </div>
            <div className={styles.downdownB}>
              <h1 style={stage > 4 ? {color: '#FDE07F'} : {}}>Review</h1>
              <h2 className={styles.mobileOnly} style={stage === 5 ? {color: '#FDE07F'} : {display: "none"}}>Review</h2>
              <h1 style={stage > 5 ? {color: '#FDE07F'} : {}}>Deploy</h1>
              <h3 className={styles.mobileOnly} style={stage === 6 ? {color: '#FDE07F'} : {display: "none"}}>Deploy</h3>
            </div>
          </div>
          <div className={styles.square}>

          </div>
        </div>
      </div>
    </div>

  );
}
export default Status;