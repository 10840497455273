import styles from './ProjectCard.module.css';
import {Link } from 'react-router-dom';
function ProjectCard(props) {
  return (
    <Link to={props.link}><div className={styles.target}>
      <div className={styles.card}>
        <div className={styles.left}>
          <div className={styles.leftContent}>
            <h1>{props.title}</h1>
            <h2>{props.tag}</h2>
            <h3 style={{ color: props.color}}>{props.status}</h3>
          </div>
        </div>
        <div className={styles.right}>
          <img src={props.logo} className={styles.logo} alt="Project Logo." />
        </div>
      </div>
    </div>
    </Link>
  );
}
export default ProjectCard;