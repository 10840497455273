/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import styles from './About.module.css';
import AboutImage from '../../../../assets/images/taxiMe.svg';
import {ButtonG, ButtonM, Title} from '../../../../components/components';
import AboutBird from '../../../../assets/images/taxiBird.svg';
import {client} from '../../../../client';

function About() {

  const [about, setAbout] = useState([]);


  useEffect(() => {
    execute();
  }, []);

  const linkedin = "https://www.linkedin.com/in/" + about.linkedinUsername;

  const execute = async () => {
    const query = '*[_type == "about"]';
    await client.fetch(query).then((data) => {setAbout(data[0]);});
  }


  return (

    
    <div className={styles.todo}>
      <div className={styles.wrapper}>

        <div className={styles.leftAbout}>
          <img src={AboutImage} alt="A Guy Programming" />
        </div>

        <div className={styles.topAbout}>
         <Title title={"About Me"}></Title>
          <h2>{about.description}</h2>
            <ButtonG link={linkedin} name={"linkedin"} icon={"FaLinkedin"}></ButtonG>
        </div>

        <div className={styles.mobileOnly}>
          <div className={styles.title}> <Title title={"About Me"}></Title></div>

          <h2>{about.description}</h2><br/>
          <h2>{about.status}</h2>
          <ButtonM link={linkedin} name={"linkedin"} icon={"FaLinkedin"}></ButtonM>
        </div>

        <div className={styles.bottomAbout}>
          <h2>{about.status}</h2>
        </div>

        <div className={styles.bird}>
          <img src={AboutBird} alt="An Adorable Bird" />
        </div>

      </div>
    </div>
  );
}

export default About;

