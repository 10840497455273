/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from 'react';
import styles from './Education.module.css';
import { Title } from '../../../../components/components'
import { client } from '../../../../client';

function Education() {

  const [education, setEducation] = useState([]);

  useEffect(() => {
    execute();
  }, []);

  const execute = async () => {
    const query = `*[_type == "education"]{
      description, 
      "resumeURL": resume.asset->url,
      events[]->{title, type, location, ingress, egress}
    }`;

    await client.fetch(query).then((data) => { setEducation(data[0]);});
  }


  return (

    <div className={styles.todo}>
      <div className={styles.wrapper}>



        <div className={styles.left}>
        <div className={styles.target}></div>
          <Title title={"Education"}></Title>
          <p>{education.description}</p>
          <div className={styles.link}><a href={education?.resumeURL + ""} rel="noreferrer" target="_blank">see curriculum »</a></div>
        </div>

        <div className={styles.right}>
          <div className={styles.timeline}>
            <div className={styles.a}>
              {
                education.events?.map((event, index) => (
                  <div key={event.title + index} className={styles.item}>
                    <h1>{event.title} - {event.type}</h1>
                    <h2>{event.location}</h2>
                    <h3>{event.ingress.split("-")[0]} - {event.egress.split("-")[0]}</h3>
                  </div>
                ))
              } 
             
            </div>

            <div className={styles.b}>
              <div className={styles.line}>
                {
                     education.events?.map((event, index) => (
                      <div key={"dot" + index} className={styles.dot}></div>
                    ))
                }
               
              </div>

            </div>
          </div>

          <div className={styles.justline}>
            <div className={styles.a}>

            </div>

            <div className={styles.b}>
              <div className={styles.line}></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Education;

