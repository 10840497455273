/* eslint-disable jsx-a11y/anchor-has-content */
import styles from './Loader.module.css';

function Loader() {
  const getRandomInt = (max) => {
    const select = Math.floor(Math.random() * max);
    switch (select) {
      case 0:
        return <p>Cows have best friends and get stressed when they're separated.</p>
      case 1:
        return <p>The tallest waterfall in the world is Angel Falls in Venezuela.</p>
      case 2:
        return <p>Brazil was the last country in the Americas to abolish slavery.</p>
      case 3:
        return <p>The shortest scheduled flight in the world is only 2 minutes long.</p>
      case 4:
        return <p>A single sneeze travels 160km/h, shooting 100K germs into the air.</p>
      case 5:
        return <p>The human eye can distinguish about 10 million different colors.</p>
      case 6:
        return <p>The world's oldest piece of chewing gum is over 9,000 years old.</p>
      case 7:
        return <p>The first video ever uploaded to YouTube was only 18 seconds long.</p>
      case 8:
        return <p>Up to 80% of the world’s oxygen is produced underwater in the sea.</p>
      case 9:
        return <p>The world's deepest postbox is located about 10 meters underwater.</p>
      case 10:
        return <p>There are more trees on the planet than stars in the solar system.</p>
      case 11:
        return <p>A person can breathe over than 200 million times in their lifetime.</p>
      case 12:
        return <p>It takes eight minutes for the light travel from the Sun to Earth.</p>
      case 13:
        return <p>The first recorded use of money was in Mesopotamia around 2500 BC.</p>
      case 14:
        return <p>The shortest complete sentence in the English language is "I am".</p>
      case 15:
        return <p>The average person spends 6 months of their life waiting in line.</p>
      case 16:
        return <p>In Russia, hiccups are a sign that somebody is thinking of you.</p>
      case 17:
        return <p>Sloths can take up to a month to completely digest a single leaf.</p>
      case 18:
        return <p>The first modern Olympic Games were in Athens, Greece, in 1896.</p>
      case 19:
        return <p>The Dead Sea is so salty that people can easily float on its surface.</p>
      case 20:
        return <p>The speed of light is approximately 299,792,458 meters per second.</p>
      case 21:
        return <p>The only way penguins can ingest water is by swallowing as ice cubes.</p>
      case 22:
        return <p>Bees recognize human faces and remember them for their entire lives.</p>
      case 23:
        return <p>Camels have three eyelids to protect themselves from blowing sand.</p>
      case 24:
        return <p>The Giant Sequoia is the largest living tree in the world by volume.</p>
      case 25:
        return <p>A single oak tree can host up to 500 different species of insects.</p>
  
      default:
        return
    }
  }




  return (
    <div className={styles.todo}>
      <div className={styles.wrapper}>

        <div className={styles.xloader}></div>
        <div className={styles.decision}>
          <h1>Did You Know?</h1>
          {getRandomInt(25)}
        </div>


      </div>
    </div>
  );

}

export default Loader;

