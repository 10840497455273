/* eslint-disable jsx-a11y/anchor-has-content */
import styles from './Post.module.css';
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { urlFor, client } from "../../client";
import { Button } from '../../components/components'
import ReactMarkdown from "react-markdown"
import NotFound from "../NotFound"
import Loader from "../../components/Loader"
import { MdOutlineNorth, MdOutlineLibraryBooks, MdPlayCircleOutline } from "react-icons/md";
import { Helmet } from "react-helmet";



function Post() {


  const { slug } = useParams();
  const [post, setPost] = useState([]);

  useEffect(() => {
    execute();

  }, [slug]);


  const makeMonth = (date) => {
    switch (date) {
      case '01':
        return "Jan"
      case '02':
        return "Feb"
      case '03':
        return "Mar"
      case '04':
        return "Apr"
      case '05':
        return "May"
      case '06':
        return "Jun"
      case '07':
        return "Jul"
      case '08':
        return "Aug"
      case '09':
        return "Sep"
      case '10':
        return "Oct"
      case '11':
        return "Nov"
      case '12':
        return "Dec"
      default:

    }
  }

  const [isLoading, setIsLoading] = useState(true);


  const title = post?.title
  const des = post?.summary
  const execute = async () => {
    const query = `*[slug.current == "${slug}"]{hasVideo, videoLink, title, summary, isCultural, date, banner, button, description, repository, logo, status->{name, color}, tags[]->{name}}`;

    await client.fetch(query).then((data) => {
      setPost(data[0])
      setIsLoading(false);
    });

    

  }
  if (isLoading) {
    return <Loader></Loader> }
  else if (!post){
return <NotFound></NotFound>
  } else {
    if (post?.title) {
      return (


        <div className={styles.todo}>
          <Helmet>
            <title>{title}</title>
            <meta property="og:title" content={title} />
            <meta property="og:description"
              content={des} />
            <meta name="description"
              content={des}></meta>
          </Helmet>
          <Link to={post.isCultural ? "/blog/art&culture" : "/blog/science&engineering"} aria-label="Go Back">
            <div className={styles.redirect}>
              <p><MdOutlineLibraryBooks /></p>
            </div>
          </Link>
          <div className={styles.wrapper} id="top">
            <div className={styles.header}>
              <h1>{post.title}</h1>
              <h2>{post.summary}</h2>
              <h3>{
                post?.tags?.map((tag, num) => (

                  num === post?.tags?.length - 1 ? tag.name : tag.name + ", "

                ))}
              </h3>

              <div className={styles.buttons}> 
              <Button link={post.repository} icon={post.button}></Button> 
               {post.hasVideo &&  <a target="_blank" rel='noreferrer' href={post.videoLink}><div  className={styles.player}>< MdPlayCircleOutline /> Play Video</div></a>}
              </div>


            </div>

            <div className={styles.banner}>
              <img src={post.banner ? urlFor(post.banner).url() : "n"} alt="Banner Highlight" />
            </div>

            <div className={styles.text}>
              <ReactMarkdown>
                {post.description}
              </ReactMarkdown>
            </div>

            <div className={styles.footer}>
              <a href="#top">< MdOutlineNorth /><br></br>top</a>
              <p>{

                makeMonth(post?.date?.split("-")[1])

              } {post?.date?.split("-")[2].slice(0, 2)}, {post?.date?.split("-")[0]} at {post?.date?.split(":")[0].slice(-2)}h{post?.date?.split(":")[1]}</p>

            </div>
          </div>
        </div>
      );
    }
    else {
      return <NotFound />
    }
  }


}

export default Post;

